@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@include mat.core();

$maderopalette: (
  50: #faf8ed,
  100: #f2ecd3,
  200: #eae0b5,
  300: #e1d497,
  400: #daca81,
  500: #d4c16b, // Cor primária
  600: #cfbb63,
  700: #c9b358,
  800: #c3ab4e,
  900: #b99e3c,
  A100: #ffffff,
  A200: #fff5d4,
  A400: #ffeaa1,
  A700: #ffe488,
  contrast: (
    50: #ffffff,
    100: #ffffff,
  )
);

$madero-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
);

$primary: mat.define-palette($maderopalette, 500);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $madero-typography,
    density: 0,
  )
);

$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$pink-palette),
    accent: mat.define-palette(mat.$blue-grey-palette),
  ),
  typography: $madero-typography,
  density: 0,
));

@include mat.core-theme($dark-theme);
@include mat.button-theme($dark-theme);

@media (prefers-color-scheme: light) {
  @include mat.core-color($theme);
  @include mat.button-color($theme);
}

@include mat.button-typography($madero-typography);
@include mat.typography-hierarchy($madero-typography);

@include mat.all-component-themes($theme);

:host {
  @include mat.all-component-themes($theme);
}

.dark {
  @include mat.all-component-themes($dark-theme);
}

:root {
  --opacity-8: 0.8;

  --white: #ffffff;
  --black: #000000;

  --transparent: transparent;
  --primary: #d4c16b;
  --accent: #ffffff;
  --primary-smoth: rgba(212, 193, 107, 0.2);

  --primary-opacity-8: rgba(5, 64, 211, var(--opacity-8));

  --support-color-1: #03b6d6;

  --neutral-default: #efefef;
  --neutral-default-opacity-8: rgba(239, 239, 239, var(--opacity-8));
  --neutral-darkest: #54565b;
  --neutral-dark: #686868;
  --neutral-medium: #cdcdcd;
  
  --suport-1: #6b6b6b;
  --suport-2: #16b138;
  --suport-3: #ffb400;
  --suport-4: #ee3f4d;

  --theme-background: #ffffff;
  --theme-color: #000000;
}

:root[theme=dark] {
  --theme-background: #000000;
  --theme-color: #ffffff;
}


body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--theme-background);
  color: var(--theme-color);
}

.btn-default {
  border-radius: 99px !important;
  height: 51px;
  color: var(--white) !important;
  &__text {
    display: flex;
    align-items: center;
    padding-right: 8px;
    &--icon{ padding-right: 8px; }
  }
  &__loader {
    display: flex;
    align-items: center;
    &--spinner {
      padding-right: 16px;
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.c-content-page{
  margin: 32px 64px;
  &__header{
      color: var(--neutral-darkest);
      hr{
          border: 1px solid var(--primary);
      }
      p{ color: var(--neutral-dark); }
  }
}
